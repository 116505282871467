<template>
  <div>

    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="supplier_id">{{ $t('supplier') }}</label>
                <multiselect id="supplier_id" v-model="supplier"
                             :placeholder="$t('purchases_payments_periodic_reports.supplier')"
                             label="full_name"
                             track-by="id"
                             :options="suppliers"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getSuppliers($event)">
                </multiselect>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('suppliers_reports.country') }}</label>
                <div class="input-group">
                  <multiselect v-model="country"
                               :placeholder="$t('suppliers_reports.country')"
                               label="name"
                               track-by="code2"
                               :options="countries"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getCountries($event)">
                  </multiselect>
                </div>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('suppliers_reports.city') }}</label>
                <div class="input-group">
                  <multiselect v-model="city"
                               :placeholder="$t('suppliers.city')"
                               label="name"
                               track-by="id"
                               :options="cities"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                </div>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('suppliers_reports.branch') }}</label>
                <multiselect v-model="branch"
                             :placeholder="$t('suppliers_reports.branch')"
                             label="name"
                             track-by="id"
                             :options="branches"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('currency') }}</label>
                <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                  <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                </select>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('suppliers_reports.credit_min') }}</label>
                <input v-model="filters.credit_min" type="number" id="credit_min" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('suppliers_reports.credit_max') }}</label>
                <input v-model="filters.credit_max" type="number" id="credit_max" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('suppliers_reports.debit_min') }}</label>
                <input v-model="filters.debit_min" type="number" id="debit_min" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('suppliers_reports.debit_max') }}</label>
                <input v-model="filters.debit_max" type="number" id="debit_max" class="form-control">
              </div>

              <div class="form-group d-inline-flex col-md-6 mt-10 m-0">
                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  {{ $t('display_report') }}
                </button>
                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <div class="card card-custom">
      <div class="card-body">
        <b-tabs content-class="mt-3">
          <b-tab active :title="$t('details')">
            <!-- Filter -->
            <div>
              <div class="row justify-content-end">
                <div class="col-12 mt-5 mb-5 d-flex justify-content-end">
                  <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                    <!--                <download-excel-->
                    <!--                    class="dropdown-item"-->
                    <!--                    :fetch="dataReport"-->
                    <!--                    :fields="json_fields"-->
                    <!--                    :name="$t('suppliers_reports.suppliers_reports')+'.xls'">-->
                    <!--                  <i class="la la-file-excel"></i>{{ $t('excel') }}-->
                    <!--                </download-excel>-->
                    <button class="dropdown-item" @click="convertTableToExcelReport('summaryTable')">
                      <span><i class="fa fa-file-excel"></i> <span>{{ $t('excel') }}</span></span>
                    </button>
                    <button class="dropdown-item" @click="printData('summaryTable', 'print')">
                      <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                    </button>
                    <!--                <button class="dropdown-item" @click="printPDFData('summaryTable', 'pdf')">-->
                    <!--                  <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>-->
                    <!--                </button>-->
                    <button class="dropdown-item" @click="getAllDataForExport('sectionForExport', 'excel')">
                      <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                    </button>
                  </b-dropdown>
                </div>
              </div>
            </div>

            <div class="col-12 mt-10">
              <div class="table-responsive">
                <table class="table table-bordered" id="summaryTable">
                  <thead>
                  <tr id="myRow">
                    <th>{{ $t('suppliers_reports.supplier_code') }}</th>
                    <th>{{ $t('suppliers_reports.full_name') }}</th>
                    <th>{{ $t('suppliers_reports.business_no') }}</th>
                    <th>{{ $t('suppliers_reports.address') }}</th>
                    <th>{{ $t('suppliers_reports.mobile') }}</th>
                    <th>{{ $t('suppliers_reports.phone') }}</th>
                    <th>{{ $t('suppliers_reports.supplier_balance') }}</th>

                  </tr>
                  </thead>
                  <tbody>

                  <template v-if="filters.group_by == 'branch'">
                    <template v-for="(row2, index2) in data">
                      <tr>
                        <td colspan="7">{{ index2 }}</td>
                      </tr>
                      <tr v-for="(row, index) in row2" :key="'wwwwww'+index+index2" v-if="row">
                        <td>{{ row.supplier_code ? row.supplier_code : '' }}</td>
                        <td>{{ row.full_name ? row.full_name : '' }}</td>
                        <td>{{ row.business_no ? row.business_no : '' }}</td>
                        <td>{{ row.address ? row.address : '' }}</td>
                        <td><span class="span-tel">{{ row.mobile }}</span></td>
                        <td><span class="span-tel">{{ row.phone }}</span></td>
                        <td>
                          <span class="d-block" v-for="(_balance, _index) in row.supplier_balance" :key="'bb'+_index+index+index2">{{ _balance }}</span>
                        </td>
                      </tr>
                    </template>
                  </template>


                    <tr v-if="filters.group_by == 'supplier'" v-for="(row2, index2) in data" :key="'ddddd'+index2">
                      <td>{{ row2.supplier_code }}</td>
                      <td>{{ row2.full_name }}</td>
                      <td>{{ row2.business_no }}</td>
                      <td>{{ row2.address }}</td>
                      <td><span class="span-tel">{{ row2.mobile }}</span></td>
                      <td><span class="span-tel">{{ row2.phone }}</span></td>
                      <td>
                        <span class="d-block" v-for="(_balance, index) in row2.supplier_balance" :key="'fffffbb'+index">{{ _balance }}</span>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-12 mt-5 text-center">
              <button class="btn btn-warning" @click="loadMore">
                <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                {{ $t('load_more') }}
              </button>
            </div>

          </b-tab>
          <template #tabs-end>
            <li class="nav-item  d-flex my-auto">
              <select id="" v-model="filters.group_by" class="custom-select border-0 pt-0 pb-0 h-auto shadow-none" name="">
                <option :value="null" disabled>{{ $t('select_filter') }}</option>
                <option value="supplier">{{ $t('supplier') }}</option>
                <option value="branch">{{ $t('branch') }}</option>
              </select>

            </li>
          </template>
        </b-tabs>
        <!-- End Filter -->

        <!--        {-->
        <!--        "id": 88,-->
        <!--        "business_name": "kh15",-->
        <!--        "supplier_code": "SUPP-0000088",-->
        <!--        "buisness_no": "",-->
        <!--        "full_name": "kh ",-->
        <!--        "address": "Mecca",-->
        <!--        "mobile": "+20 105 241 5412",-->
        <!--        "branch_id": 1,-->
        <!--        "branch": "الفرع الاساسي",-->
        <!--        "phone": "",-->
        <!--        "supplier_balance": [-->
        <!--        "0 >> 250 >> SAR"-->
        <!--        ]-->
        <!--        }-->


        <!--        <v-server-table  :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table" id="clientReportsTable">-->
        <!--          <template slot="mobile" slot-scope="props">-->
        <!--            <span class="span-tel">{{ props.row.mobile }}</span>-->
        <!--          </template>-->
        <!--          <template slot="phone" slot-scope="props">-->
        <!--            <span class="span-tel">{{ props.row.phone }}</span>-->
        <!--          </template>-->
        <!--          <template slot="supplier_balance" slot-scope="props">-->
        <!--            <span class="d-block" v-for="(_balance, index) in props.row.supplier_balance" :key="'aa'+index">{{ _balance }}</span>-->
        <!--          </template>-->
        <!--        </v-server-table>-->

        <!--                <button class="btn btn-warning" @click="loadMore">-->
        <!--                    <p class="mb-0"><b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon> {{$t('load_more')}}</p>-->
        <!--                </button>-->
      </div>
    </div>

    <div class="export-container">
      <div class="section-for-export">
        <table class="table table-bordered" id="sectionForExport">
          <thead>
          <tr id="myRow">
            <th>{{ $t('suppliers_reports.supplier_code') }}</th>
            <th>{{ $t('suppliers_reports.full_name') }}</th>
            <th>{{ $t('suppliers_reports.address') }}</th>
            <th>{{ $t('suppliers_reports.mobile') }}</th>
            <th>{{ $t('suppliers_reports.phone') }}</th>
            <th>{{ $t('suppliers_reports.supplier_balance') }}</th>

          </tr>
          </thead>
          <tbody>

          <template v-if="filters.group_by == 'branch'">
            <template v-for="(row2, index2) in dataList">
              <tr>
                <td colspan="7">{{ index2 }}</td>
              </tr>
              <tr v-for="(row, index) in row2" :key="'wefewfewfewfew'+index+index2" v-if="row2">
                <td>{{ row.supplier_code ? row.supplier_code : '' }}</td>
                <td>{{ row.full_name ? row.full_name : '' }}</td>
                <td>{{ row.business_no ? row.business_no : '' }}</td>
                <td>{{ row.address ? row.address : '' }}</td>
                <td><span class="span-tel">{{ row.mobile }}</span></td>
                <td><span class="span-tel">{{ row.phone }}</span></td>
                <td>
                  <span class="d-block" v-for="(_balance, _index) in row.supplier_balance" :key="'bb'+_index+index+index2">{{ _balance }}</span>
                </td>
              </tr>
            </template>
          </template>

          <template v-if="filters.group_by == 'supplier'" v-for="(row2, index2) in dataList">
            <tr>
              <td>{{ row2.supplier_code }}</td>
              <td>{{ row2.full_name }}</td>
              <td>{{ row2.business_no }}</td>
              <td>{{ row2.address }}</td>
              <td><span class="span-tel">{{ row2.mobile }}</span></td>
              <td><span class="span-tel">{{ row2.phone }}</span></td>
              <td>
                <span class="d-block" v-for="(_balance, index) in row2.supplier_balance" :key="'vvvvv'+index">{{ _balance }}</span>
              </td>
            </tr>
          </template>

          </tbody>
        </table>
      </div>
    </div>
    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <report-main-header :print-type="'SuppliersReports'"></report-main-header>
      </div>
    </div>
    <div class="selector-export" id="selectorExport"></div>
    <!--end::customer-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";
import TableToExcel from "@linways/table-to-excel";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";
// import TableToExcel from "@linways/table-to-excel";


export default {
  name: "index-suppliers",
  components: {ReportMainHeader},
  data() {
    return {
      mainRoute: '/reports/purchases/supplier',
      mainRouteDependency: 'base/dependency',

      showAdvancedSearch: false,
      dataList: [],
      data: [],

      filters: {
        supplier_id: null,
        currency_id: null,
        branch_id: null,
        credit_min: null,
        credit_max: null,
        debit_min: null,
        debit_max: null,
        group_by: 'supplier',
        city_id: null,
        country_code: null,
        limit: 15,
      },
      columns: ['supplier_code', 'full_name', 'address', 'mobile', 'phone', 'supplier_balance'],

      countries: [],
      category: null,
      categories: [],
      cities: [],
      country: null,
      city: null,
      branch: null,
      branches: [],
      page: 1,
      currencies: [],
      supplier: [],
      suppliers: [],
      table_loading: false,
    }
  },
  watch: {
    country: function (val) {
      if (val) {
        this.filters.country_code = val.code2;
        this.getCities(val.code2);
      } else {
        this.country = null;
      }
    },
    city: function (val) {
      if (val) {
        this.filters.city_id = val.id;
      } else {
        this.city = null;
      }
    },
    "filters.country_code": function (val) {
      if (val) {
        this.getCities(val);
      }
    },
    "filters.group_by": function (val) {
      this.doFilter();
    },
    supplier: function (val) {
      if (val) {
        this.filters.supplier_id = val.id;
      } else {
        this.filters.supplier_id = null;
      }
    },
    branch: function (val) {
      if (val) {
        this.filters.branch_id = val.id;
      } else {
        this.filters.branch_id = null;
      }
    },

  },
  computed: {
    json_fields: function () {
      let fields = {};
      fields[this.$t('suppliers_reports.supplier_code')] = 'supplier_code';
      fields[this.$t('suppliers_reports.full_name')] = 'full_name';
      fields[this.$t('suppliers_reports.address')] = 'address';
      fields[this.$t('suppliers_reports.mobile')] = 'mobile';
      fields[this.$t('suppliers_reports.phone')] = 'phone';
      fields[this.$t('suppliers_reports.supplier_balance')] = 'supplier_balance';


      return fields;
    },
    headings: function () {
      let that = this;
      let list = {
        supplier_code: that.$t('suppliers_reports.supplier_code'),
        full_name: that.$t('suppliers_reports.full_name'),
        address: that.$t('suppliers_reports.address'),
        mobile: that.$t('suppliers_reports.mobile'),
        phone: that.$t('suppliers_reports.phone'),
        supplier_balance: that.$t('suppliers_reports.supplier_balance'),
      };
      return list;
    },

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.purchases_reports'), route: '/reports/purchase-reports-links'}, {title: this.$t("MENU.suppliers_reports")}]);

  },
  created() {
    this.getCurrencies();
    this.getBranches();
  },
  methods: {
    ...cssStypeForPrintReport,

    loadMore() {
      this.page = this.page + 1;
      this.getData();
    },

    getAllDataForExport(tableId, _export) {
      ApiService.get(this.mainRoute, {params: {...this.filters, is_export: 1, limit: 999999999999999}}).then((response) => {
        this.dataList = response.data.data.data;
        setTimeout(() => {
          this.convertTableToExcelReport(tableId);
        }, 100)
      });

    },
    async getData() {
      let _limit = (this.page ? this.page : 1) * 15;
      return await ApiService.get(this.mainRoute, {params: {...this.filters, limit: _limit}}).then(response => {
        this.data = response.data.data.data;
      }).catch((error) => {
        this.$errorAlert(error);
      });
    },
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.page = 1;
      this.getData();
    },
    resetFilter() {
      this.filters.country_code = null;
      this.filters.supplier_id = null;
      this.filters.city_id = null;
      this.filters.branch_id = null;
      this.supplier = null;
      this.branch = null;
      this.filters.currency_id = null;
      this.filters.credit_min = null;
      this.filters.credit_max = null;
      this.filters.debit_min = null;
      this.filters.debit_max = null;
      this.country = null;
      this.city = null;
      this.doFilter();
    },

    getCountries(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/countries`, {params: {filter: filter}}).then((response) => {
          this.countries = response.data.data;
        });
      }
    },

    getCities(id) {
      ApiService.get(`base/dependency/cities/${id}`).then((response) => {
        this.cities = response.data.data;
      });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
        let _currency = response.data.data.find(row => row.code == 'SAR');
        if (_currency) {
          this.filters.currency_id = _currency.id;
        }
      });
    },
    getSuppliers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    },
    convertTableToExcelReport(_tableId) {
      TableToExcel.convert(document.getElementById(_tableId), {
        name: this.$t('MENU.suppliers_reports') + '.xlsx',
        sheet: {
          name: 'Sheet 1'
        }
      });
    },
    printData(tableId) {
      this.setReportLog('pdf', 'supplier report');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },
    printPDFData(tableId, type) {
      this.setReportLog('pdf', 'supplier report');

      // let promise = this.getData();
      // Promise.all([promise]).then(() => {
      exportPDFInnerAction(tableId, type, this.$t('suppliers_reports.suppliers_reports'), this.$t('suppliers_reports.suppliers_reports'));
      // })

    },

  },
};
</script>
<style>
.html2pdf__overlay {
  opacity: 0 !important;
  z-index: -1000;

}

.drowing-table, .selector-export {
  opacity: 0 !important;
  z-index: -1000 !important;
  width: 0.0001px !important;
  height: 0.0001px !important;
  overflow: auto;
}

</style>
  